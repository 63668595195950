import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PingService } from './ping.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private pingService: PingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiUrl = request.url.indexOf('/api/') > 0 ? true : false;
        if (isApiUrl && environment.localDevelopment['mock'] === true) {
            let token = window.localStorage.getItem('token');
            if (!token) {
                this.pingService.getToken().then(() => {
                    token = window.localStorage.getItem('token');
                    request = request.clone({
                        setHeaders: {
                            'x-fnma-jws-token': token
                        }
                    });
                    return next.handle(request);
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        'x-fnma-jws-token': token
                    }
                });
                return next.handle(request);
            }
        }
        else {
            return next.handle(request);
        }

    }
}