// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  localDevelopment: {
    isLocal: false,
  },
  production: false,
  pingEnvironmentUrls: {
    'devportal.fv7dp.etss.devl.intgfanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.fv7dp.etss.devl.intgfanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.devl.intgfanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/9c273a66-1cf6-42ac-8807-e92a91536074/experiences/DevPortal_RegFormSeparateRegForm',
      targetResource:
        '?TargetResource=https://devportal.fv7dp.etss.devl.intgfanniemae.com',
      hostEnvironment: 'DEV',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/10ca672c-fb9c-4c47-a924-6aeb96202385/804dd1f583c57e27_complete.js',
    },
    'devptlpub.fv7dp.etss.devl.intgfanniemae.com': {
      authPrefix: 'fv7ui-pubauth',
      host: 'devptlpub.fv7dp.etss.devl.intgfanniemae.com',
      loginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      logoutUrl: 'https://auth.pingone.com/9c273a66-1cf6-42ac-8807-e92a91536074/as/signoff',
      pingLoginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/9c273a66-1cf6-42ac-8807-e92a91536074/experiences/DevPortal_RegFormSeparateRegForm',
      targetResource: '?post_logout_redirect_uri=https://devptlpub.fv7dp.etss.devl.intgfanniemae.com?clear_cdx_session=true',
      hostEnvironment: 'DEV',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/10ca672c-fb9c-4c47-a924-6aeb96202385/804dd1f583c57e27_complete.js',
    },
    'devportal.cdxms.devl-etss.aws.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.cdxms.devl-etss.aws.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping',
      targetResource:
        '?TargetResource=https://devportal.cdxms.devl-etss.aws.fanniemae.com',
      hostEnvironment: 'DEV',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/10ca672c-fb9c-4c47-a924-6aeb96202385/804dd1f583c57e27_complete.js',
    },
    'devportal-devl.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal-devl.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.devl.intgfanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/9c273a66-1cf6-42ac-8807-e92a91536074/experiences/DevPortal_RegFormSeparateRegForm',
      targetResource: '?TargetResource=https://devportal-devl.fanniemae.com',
      hostEnvironment: 'DEV',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/10ca672c-fb9c-4c47-a924-6aeb96202385/804dd1f583c57e27_complete.js',
    },
    'devportal-devl-a.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal-devl-a.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping',
      targetResource: '?TargetResource=https://devportal-devl-a.fanniemae.com',
      hostEnvironment: 'DEV',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/10ca672c-fb9c-4c47-a924-6aeb96202385/804dd1f583c57e27_complete.js',
    },
    'devportal.fv7dp.etss.test.intgfanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.fv7dp.etss.test.intgfanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso-test.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.test.intgfanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/b566aa6b-6479-4acf-b8c2-5c0e1f4542a7/experiences/DevPortal_SeperateRegistrationPage',
      targetResource:
        '?TargetResource=https://devportal.fv7dp.etss.test.intgfanniemae.com',
      hostEnvironment: 'TEST',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/b35a1f50-6843-451e-9512-640d11de3507/8617f5f62e684b90_complete.js',
    },
    'devptlpub.fv7dp.etss.test.intgfanniemae.com': {
      authPrefix: 'fv7ui-pubauth',
      host: 'devptlpub.fv7dp.etss.test.intgfanniemae.com',
      loginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      logoutUrl: 'https://auth.pingone.com/b566aa6b-6479-4acf-b8c2-5c0e1f4542a7/as/signoff',
      pingLoginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/b566aa6b-6479-4acf-b8c2-5c0e1f4542a7/experiences/DevPortal_SeperateRegistrationPage',
      targetResource: '?post_logout_redirect_uri=https://devptlpub.fv7dp.etss.test.intgfanniemae.com?clear_cdx_session=true',
      hostEnvironment: 'TEST',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/b35a1f50-6843-451e-9512-640d11de3507/8617f5f62e684b90_complete.js',
    },
    'devportal.cdxms.test-etss.aws.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.cdxms.test-etss.aws.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-test.fanniemae.com/idp/startSLO.ping',
      targetResource:
        '?TargetResource=https://devportal.cdxms.test-etss.aws.fanniemae.com',
      hostEnvironment: 'TEST',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/b35a1f50-6843-451e-9512-640d11de3507/8617f5f62e684b90_complete.js',
    },
    'devportal-test.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal-test.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-test.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.test.intgfanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/b566aa6b-6479-4acf-b8c2-5c0e1f4542a7/experiences/DevPortal_SeperateRegistrationPage',
      targetResource: '?TargetResource=https://devportal-test.fanniemae.com',
      hostEnvironment: 'TEST',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/b35a1f50-6843-451e-9512-640d11de3507/8617f5f62e684b90_complete.js',
    },
    'devportal-test-a.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal-test-a.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-test.fanniemae.com/idp/startSLO.ping',
      targetResource: '?TargetResource=https://devportal-test-a.fanniemae.com',
      hostEnvironment: 'TEST',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/b35a1f50-6843-451e-9512-640d11de3507/8617f5f62e684b90_complete.js',
    },
    'devportal.fv7dp.etss.acpt.intgfanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.fv7dp.etss.acpt.intgfanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.acpt.intgfanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/c5335f43-3db4-4884-8794-5fed978221cf/saml20/idp/startsso?spEntityId=p1c-puma-GED-a1-saml-devportal-DirectRegistration',
      targetResource:
        '?TargetResource=https://devportal.fv7dp.etss.acpt.intgfanniemae.com',
      hostEnvironment: 'ACPT',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'devptlpub.fv7dp.etss.acpt.intgfanniemae.com': {
      authPrefix: 'fv7ui-pubauth',
      host: 'devptlpub.fv7dp.etss.acpt.intgfanniemae.com',
      loginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      logoutUrl: 'https://auth.pingone.com/c5335f43-3db4-4884-8794-5fed978221cf/as/signoff',
      pingLoginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/c5335f43-3db4-4884-8794-5fed978221cf/saml20/idp/startsso?spEntityId=p1c-puma-GED-a1-saml-devportal-DirectRegistration',
      targetResource: '?post_logout_redirect_uri=https://devptlpub.fv7dp.etss.acpt.intgfanniemae.com?clear_cdx_session=true',
      hostEnvironment: 'ACPT',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'devportal.cdxms.acpt-etss.aws.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.cdxms.acpt-etss.aws.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
      targetResource:
        '?TargetResource=https://devportal.cdxms.acpt-etss.aws.fanniemae.com',
      hostEnvironment: 'ACPT',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'developer.acptfanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer.acptfanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.acpt.intgfanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/c5335f43-3db4-4884-8794-5fed978221cf/saml20/idp/startsso?spEntityId=p1c-puma-GED-a1-saml-devportal-DirectRegistration',
      targetResource: '?TargetResource=https://developer.acptfanniemae.com',
      hostEnvironment: 'ACPT',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'theexchange.acptfanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer.acptfanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.acpt.intgfanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/c5335f43-3db4-4884-8794-5fed978221cf/saml20/idp/startsso?spEntityId=p1c-puma-GED-a1-saml-devportal-DirectRegistration',
      targetResource: '?TargetResource=https://developer.acptfanniemae.com',
      hostEnvironment: 'ACPT',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'developer-a.acptfanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer-a.acptfanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
      targetResource: '?TargetResource=https://developer-a.acptfanniemae.com',
      hostEnvironment: 'ACPT',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'devportal-perf.cdxms.acpt-etss.aws.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal-perf.cdxms.acpt-etss.aws.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
      targetResource:
        '?TargetResource=https://devportal-perf.cdxms.acpt-etss.aws.fanniemae.com',
      hostEnvironment: 'PERF',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'developer-perf.acptfanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer-perf.acptfanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.acpt.intgfanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/c5335f43-3db4-4884-8794-5fed978221cf/saml20/idp/startsso?spEntityId=p1c-puma-GED-a1-saml-devportal-DirectRegistration',
      targetResource:
        '?TargetResource=https://developer-perf.acptfanniemae.com',
      hostEnvironment: 'PERF',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'devptlpub.fv7dp.etss.perf.intgfanniemae.com': {
      authPrefix: 'fv7ui-pubauth',
      host: 'devptlpub.fv7dp.etss.perf.intgfanniemae.com',
      loginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      logoutUrl: 'https://auth.pingone.com/c5335f43-3db4-4884-8794-5fed978221cf/as/signoff',
      pingLoginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/c5335f43-3db4-4884-8794-5fed978221cf/saml20/idp/startsso?spEntityId=p1c-puma-GED-a1-saml-devportal-DirectRegistration',
      targetResource: '?post_logout_redirect_uri=https://devptlpub.fv7dp.etss.perf.intgfanniemae.com?clear_cdx_session=true',
      hostEnvironment: 'PERF',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'devportal-perf.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal-perf.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
      targetResource: '?TargetResource=https://devportal-perf.fanniemae.com',
      hostEnvironment: 'PERF',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'devptlpub.fv7dp.etss.clve.fanniemae.com': {
      authPrefix: 'fv7ui-pubauth',
      host: 'devptlpub.fv7dp.etss.clve.fanniemae.com',
      loginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      logoutUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/as/signoff',
      pingLoginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/experiences/p1c-prod-GED-p1-DevPortaloginSeparateRegistration',
      targetResource: '?post_logout_redirect_uri=https://devptlpub.fv7dp.etss.clve.fanniemae.com?clear_cdx_session=true',
      hostEnvironment: 'CLVE',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'devportal.fv7dp.etss.clve.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.fv7dp.etss.clve.fanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.clve.fanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/experiences/p1c-prod-GED-p1-DevPortaloginSeparateRegistration',
      targetResource:
        '?TargetResource=https://devportal.fv7dp.etss.clve.fanniemae.com',
      hostEnvironment: 'CLVE',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/6cea7a23-e8be-46ca-9f02-14f7697bf11c/8617f5f62e684b90_complete.js',
    },
    'devportal.cdxms.clve-etss.aws.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.cdxms.clve-etss.aws.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      targetResource:
        '?TargetResource=https://devportal.cdxms.clve-etss.aws.fanniemae.com',
      hostEnvironment: 'CLVE',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/6cea7a23-e8be-46ca-9f02-14f7697bf11c/8617f5f62e684b90_complete.js',
    },
    'developer-clve.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer-clve.fanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.clve.fanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/experiences/p1c-prod-GED-p1-DevPortaloginSeparateRegistration',
      targetResource: '?TargetResource=https://developer-clve.fanniemae.com',
      hostEnvironment: 'CLVE',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/6cea7a23-e8be-46ca-9f02-14f7697bf11c/8617f5f62e684b90_complete.js',
    },
    'developer-clve-a.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer-clve-a.fanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      targetResource: '?TargetResource=https://developer-clve-a.fanniemae.com',
      hostEnvironment: 'CLVE',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/6cea7a23-e8be-46ca-9f02-14f7697bf11c/8617f5f62e684b90_complete.js',
    },
    'devptlpub.fv7dp.etss.prod.fanniemae.com': {
      authPrefix: 'fv7ui-pubauth',
      host: 'devptlpub.fv7dp.etss.prod.fanniemae.com',
      loginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      logoutUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/as/signoff',
      pingLoginUrl: '/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/saml20/idp/startsso?spEntityId=p1c-puma-GED-p1-saml-devportal-DirectRegistration',
      targetResource: '?post_logout_redirect_uri=https://devptlpub.fv7dp.etss.prod.fanniemae.com?clear_cdx_session=true',
      hostEnvironment: 'PROD',
      googleSiteKey: '6LfdGqoaAAAAAFQ0l_UXYdwdEMh66w_TF2rIq4FO',
      dynatraceUrl:
        'https://dynatrace-le-cf.fanniemae.com:443/jstag/managed/f80ece40-a77c-4303-8ba3-8a88bad00d1f/8617f5f62e684b90_complete.js',
    },
    'devportal.fv7dp.etss.prod.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.fv7dp.etss.prod.fanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.prod.fanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/saml20/idp/startsso?spEntityId=p1c-puma-GED-p1-saml-devportal-DirectRegistration',
      targetResource:
        '?TargetResource=https://devportal.fv7dp.etss.prod.fanniemae.com',
      hostEnvironment: 'PROD',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/97527b6b-c1b9-432c-a34a-66bd79b05625/8617f5f62e684b90_complete.js',
    },
    'devportal.cdxms.prod-etss.aws.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'devportal.cdxms.prod-etss.aws.fanniemae.com',
      loginUrl: '/fv7ui-secured/?cdxRedirectPath=/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      targetResource:
        '?TargetResource=https://devportal.cdxms.prod-etss.aws.fanniemae.com',
      hostEnvironment: 'PROD',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/97527b6b-c1b9-432c-a34a-66bd79b05625/8617f5f62e684b90_complete.js',
    },
    'developer.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer.fanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.prod.fanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/saml20/idp/startsso?spEntityId=p1c-puma-GED-p1-saml-devportal-DirectRegistration',
      targetResource: '?TargetResource=https://developer.fanniemae.com',
      hostEnvironment: 'PROD',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/97527b6b-c1b9-432c-a34a-66bd79b05625/8617f5f62e684b90_complete.js',
    },
    'theexchange.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer.fanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      pingLoginUrl: 'https://devptlpub.fv7dp.etss.prod.fanniemae.com/fv7ui-pubauth/?cdxRedirectPath=/',
      registerUrl: 'https://auth.pingone.com/4c2b23f9-52b1-4f8f-aa1f-1d477590770c/saml20/idp/startsso?spEntityId=p1c-puma-GED-p1-saml-devportal-DirectRegistration',
      targetResource: '?TargetResource=https://developer.fanniemae.com',
      hostEnvironment: 'PROD',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/97527b6b-c1b9-432c-a34a-66bd79b05625/8617f5f62e684b90_complete.js',
    },
    'developer-a.fanniemae.com': {
      authPrefix: 'fv7ui-secured',
      host: 'developer-a.fanniemae.com',
      loginUrl: '/fv7ui-secured/',
      logoutUrl: 'https://fmsso.fanniemae.com/idp/startSLO.ping',
      targetResource: '?TargetResource=https://developer-a.fanniemae.com',
      hostEnvironment: 'PROD',
      googleSiteKey: '6LfQOr8aAAAAAIblTW-S409CBMTi3mjI8ALpXH9i',
      dynatraceUrl:
        'https://dynatrace-cf.fanniemae.com:443/jstag/managed/97527b6b-c1b9-432c-a34a-66bd79b05625/8617f5f62e684b90_complete.js',
    },
  },
  baseUrl: '/fnmadevportal',
  cdxEndpoints: {
    cdxAuthenticate: '/cdx/authenticate',
    userdetails: '/userdetails',
  },
  api_end_points: {
    v1: {
      cms_banner: '/v1/content?endpoint=banner-list',
      cms_use_case_list: '/v1/content?endpoint=use-cases-list',
      cms_public_api_use_case_list: '/v1/content?endpoint=public-api-use-cases',
      cms_homepage: '/v1/content?endpoint=homepage',
      cms_maintainance_banner: '/v1/content?endpoint=maintenance-banner',
      cms_release_notes: '/v1/content?endpoint=release-notes',
      terms_conditions: '/v1/content?endpoint=create-app-terms',
      cms_faq:'/v1/content?endpoint=faq',
      cms_faq_card: '/v1/content?endpoint=faq-card',
      cms_hidden_gsg: '/v1/content?endpoint=gsg-api-access'
    },
    cdxLogin: '/fv7ui-secured/',
    featureToggle: '/fv7ui-public/web/apiport/api/noauth/feature-toggle',
    postFeedback: '/fv7ui-public/web/fv7/api/noauth/v1/general/feedback',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
