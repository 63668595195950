import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedDataAccessUserModule } from '@developer-portal/shared/data-access/user';
import { SharedDataAccessFeatureToggleModule } from '@developer-portal/shared/data-access/feature-toggle';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { metaReducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ApplicationErrorComponent } from './shared/errors/application-error/application-error.component';
import { ApplicationErrorGuard } from './shared/errors/guard/application-error-guard';
import { RolesAuthGuardService } from './store/services/roles-auth-guard.service';
import { PingService } from './store/services/ping.service';
import { GoogleRecaptchaDirective } from './shared/directives/google-recaptcha.directive';
import { ApplicationGlobalErrorComponent } from './shared/application-global-error/application-global-error.component';
import * as fromApp from './store/reducers';
import { environment } from '../environments/environment';
import { FeedbackComponent } from './feedback/feedback.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpPostFeedbackService } from './feedback/store/services/http-post-feedback.service';
import { PostFeedbackEffects } from './feedback/store/effects/post-feedback.effect';
import * as fromFeedback from './feedback/store/reducers/index';
import { MaintenanceBannerEffects } from './store/effects/maintenance-banner.effects';
import { BannerEffects } from './store/effects/banner.effects';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { HttpConfigInterceptor } from './store/services/Jwt.interceptors';

@NgModule({
  declarations: [
    AppComponent,
    ApplicationErrorComponent,
    GoogleRecaptchaDirective,
    ApplicationGlobalErrorComponent,
    FeedbackComponent  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    StoreModule.forRoot(fromApp.appReducer, { metaReducers }),
    StoreModule.forFeature('App', fromApp.mAppReducer),
    StoreModule.forFeature('Error', fromApp.errorReducer),
    StoreModule.forFeature(
      'MaintenanceBannerCMS',
      fromApp.createCmsContentReducer
    ),
    StoreModule.forFeature('PostFeedback', fromFeedback.feedbackReducers),
    StoreModule.forFeature(
      'OpenFeedbackModal',
      fromFeedback.showFeedbackModalReducers
    ),
    StoreModule.forFeature(fromApp.featureKey, fromApp.createbannerListReducer),
    SharedDataAccessUserModule,
    SharedDataAccessFeatureToggleModule,
    EffectsModule.forRoot([
      AppEffects,
      MaintenanceBannerEffects,
      BannerEffects,
      PostFeedbackEffects,
    ]),
    StoreDevtoolsModule.instrument({
      name: 'Developer Portal',
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    ApplicationErrorGuard,
    RolesAuthGuardService,
    PingService,
    HttpPostFeedbackService,
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
