import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PingService {
  constructor(private http: HttpClient) {}

  public getEnvironmentContext(): string {
    let loginContext: string;
    const origin = window.location.host;

    if (environment.pingEnvironmentUrls[origin] !== undefined) {
      loginContext = environment.pingEnvironmentUrls[origin];
    } else {
      loginContext = '';
    }

    return loginContext;
  }

  public pingLoginUrl(): string {
    const environmentContext: string = this.getEnvironmentContext();
    const pingLoginContext: string = environmentContext['loginUrl'];
    if (pingLoginContext !== undefined || pingLoginContext !== null) {
      return pingLoginContext;
    }

    return '#';
  }

  public pingPubLoginUrl(): string {
    const environmentContext: string = this.getEnvironmentContext();
    const pingLoginContext: string = environmentContext['pingLoginUrl'];

    if (pingLoginContext !== undefined || pingLoginContext !== null) {
      return pingLoginContext;
    }

    return '#';
  }

  public pingSignupUrl(): string {
    const environmentContext: string = this.getEnvironmentContext();
    const pingLoginContext: string = environmentContext['registerUrl'];

    if (pingLoginContext !== undefined || pingLoginContext !== null) {
      return pingLoginContext;
    }

    return '#';
  }

  public pingLogOutUrl(): string {
    const environmentContext: string = this.getEnvironmentContext();
    const pingLogoutContext: string =
      environmentContext['logoutUrl'] + environmentContext['targetResource'];

    return pingLogoutContext;
  }

    /***
   *  THIS METHOD INTENDED TO USE FOR LOCAL DEVELOPMENT ONLY TO CONNECT WITH THE MOCK SEVER
   */
    public async getToken() {
      if (environment.localDevelopment.isLocal && environment.localDevelopment['mock'] === true) {
        let token = window.localStorage.getItem('token');
        if (!token) {
          const environmentContext: string = this.getEnvironmentContext();
          const requestData: string = environmentContext['mockreq'];
          const url: string = environmentContext['mockurl'];
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
          });
          let tokenResp = await this.http.post<any>(url, requestData, { headers: headers }).toPromise();
          window.localStorage.setItem('token', tokenResp.access_token);
        }
      }
    }
}
